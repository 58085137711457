import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { LatLngExpression } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { BlobServiceClient } from '@azure/storage-blob';

const App: React.FC = () => {
  const [position, setPosition] = useState<LatLngExpression>([51.505, -0.09]);
  const [file, setFile] = useState<File | null>(null);
  const [text, setText] = useState<string>("");

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((pos) => {
      setPosition([pos.coords.latitude, pos.coords.longitude]);
    });
  }, []);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  };

  const uploadFileToAzure = async (file: File) => {
    // Der SAS-URL deines Azure Blob Storage Containers
    const sasUrl = 'https://sttappcore.blob.core.windows.net/images?st=2024-07-12T14:40:20Z&se=2024-07-13T22:40:20Z&si=test&spr=https&sv=2022-11-02&sr=c&sig=li3gsQNllNZnWH%2FTXKo9mZhF76bxmk5LREvOhoNPFS8%3D';

    const blobServiceClient = new BlobServiceClient(sasUrl);
    const containerClient = blobServiceClient.getContainerClient('<your-container>');
    const blockBlobClient = containerClient.getBlockBlobClient(file.name);

    try {
      const uploadBlobResponse = await blockBlobClient.uploadBrowserData(file);
      console.log(`Upload abgeschlossen: ${uploadBlobResponse.requestId}`);
    } catch (error) {
      console.error('Fehler beim Hochladen:', error);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (file) {
      uploadFileToAzure(file);
    }
    console.log("Text:", text);
    console.log("Position:", position);
    // Hier kannst du den Text und die Position auf deinem Server speichern, falls nötig
  };

  return (
    <div>
      <MapContainer center={position} zoom={13} style={{ height: "50vh", width: "100%" }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={position}>
          <Popup>
            Dein Standort
          </Popup>
        </Marker>
      </MapContainer>
      <form onSubmit={handleSubmit}>
        <input type="file" onChange={handleFileChange} />
        <textarea value={text} onChange={handleTextChange}></textarea>
        <button type="submit">Hochladen</button>
      </form>
    </div>
  );
}

export default App;